import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface PictureAndDescriptionSkeletonProps {
  reverseSection?: boolean;
}

const PictureAndDescriptionsSkeleton = ({
  reverseSection,
}: PictureAndDescriptionSkeletonProps) => {
  return (
    <div className="grid md:grid-cols-2 gap-32 md:gap-64">
      <div
        className={clsx('flex flex-col justify-center', {
          'order-2': reverseSection,
        })}
      >
        <div className={clsx('md:w-1/2', { 'md:w-full': reverseSection })}>
          <Skeleton height={48} />
        </div>
        <div className="pt-8 pb-48">
          <Skeleton height={18} />
          <Skeleton height={18} />
          <Skeleton height={18} />
          {reverseSection && <Skeleton height={18} />}
        </div>
        <div>
          <Skeleton height={18} />
          <Skeleton height={18} />
          {!reverseSection && (
            <>
              <Skeleton height={18} />
              <Skeleton height={18} />
            </>
          )}
        </div>
        {reverseSection && (
          <div className="md:w-1/2 pt-40">
            <Skeleton height={56} />
          </div>
        )}
      </div>
      <div className="md:py-[7.6rem]">
        <Skeleton height={296} />
      </div>
    </div>
  );
};

export default PictureAndDescriptionsSkeleton;
