import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const GridSkeletonElement = () => {
  return (
    <div className="md:max-w-[26.8rem]">
      <Skeleton height={20} width={96} />
      <div className="py-20">
        <Skeleton height={268} />
      </div>
      <Skeleton height={24} count={2} />
    </div>
  );
};

export default GridSkeletonElement;
