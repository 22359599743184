import 'react-loading-skeleton/dist/skeleton.css';
import BreadcrumbsSkeletonItem from './BreadcrumbsSkeletonItem';

const BreadcrumbsSkeleton = () => {
  return (
    <div className="flex flex-wrap pt-20 pb-48 md:pb-64">
      <BreadcrumbsSkeletonItem />
      <BreadcrumbsSkeletonItem />
      <BreadcrumbsSkeletonItem />
      <BreadcrumbsSkeletonItem isLastChild />
    </div>
  );
};

export default BreadcrumbsSkeleton;
