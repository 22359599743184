import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const NavSkeleton = () => {
  return (
    <div className="flex justify-between items-center py-24">
      <div>
        <Skeleton height={26} width={205} />
      </div>
      <div className="hidden lg:block">
        <Skeleton
          containerClassName="flex gap-32"
          height={22}
          width={80}
          count={5}
          inline
        />
      </div>
      <div className="grid gap-8 lg:hidden">
        <Skeleton
          containerClassName="flex flex-col gap-[0.5rem]"
          height={10}
          width={50}
          count={3}
          inline
        />
      </div>
    </div>
  );
};

export default NavSkeleton;
