import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import BreadcrumbsSkeleton from '../BreadcrumbsSkeleton';

const HeroSkeleton = () => {
  return (
    <div>
      <div>
        <BreadcrumbsSkeleton />
      </div>
      <div className="grid md:grid-cols-2 gap-32 md:gap-64">
        <div className="flex flex-col justify-center">
          <div className="md:w-3/4 pb-24">
            <Skeleton height={48} />
            <Skeleton height={48} width={'80%'} />
          </div>
          <div>
            <Skeleton height={18} count={8} />
          </div>
        </div>
        <div>
          <Skeleton height={532} width={'100%'} />
        </div>
      </div>
    </div>
  );
};

export default HeroSkeleton;
