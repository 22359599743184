import Container from '@components/containers/Container';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FooterSkeletonTile from './FooterSkeletonTile';

const FooterSkeleton = () => {
  return (
    <div>
      <div className="border-t-[.1rem] border-neutral-1">
        <Container>
          <div className="py-42 md:py-56 grid grid-cols-12">
            <div className="flex col-span-full md:col-span-9 flex-wrap gap-42 md:mr-48 mb-32 md:mb-0">
              <FooterSkeletonTile textLinesCount={5} />
              <FooterSkeletonTile textLinesCount={2} />
              <FooterSkeletonTile textLinesCount={3} />
              <FooterSkeletonTile textLinesCount={1} />
            </div>
            <div className="col-span-full md:col-span-3">
              <div className="flex justify-between items-center pb-16">
                <Skeleton height={16} width={150} />
                <Skeleton height={32} width={32} />
              </div>
              <Skeleton height={25} />
              <div className="py-24 mb-24">
                <div className="mb-4">
                  <Skeleton height={24} count={2} />
                </div>
                <Skeleton height={14} count={4} />
              </div>
              <Skeleton height={24} count={2} />
            </div>
          </div>
        </Container>
      </div>
      <div className="border-t-[.1rem] border-neutral-1">
        <Container className="py-42 md:py-56 flex justify-between items-center flex-wrap gap-12">
          <div className="flex gap-12 items-center">
            <Skeleton height={24} width={80} />
            <div className="w-4 h-4 bg-neutral-4 rounded-full"></div>
            <Skeleton height={24} width={130} />
          </div>
          <div className="flex gap-16">
            <Skeleton height={32} width={32} />
            <Skeleton height={32} width={32} />
            <Skeleton height={32} width={32} />
          </div>
          <div className="flex items-center gap-16">
            <Skeleton height={24} width={85} />
            <Skeleton height={24} width={112} />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default FooterSkeleton;
