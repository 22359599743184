import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const QuoteSkeleton = () => {
  return (
    <div className="grid md:grid-cols-2 gap-48 md:gap-96">
      <div>
        <div className="flex flex-col justify-between h-full">
          <div className="md:w-1/2">
            <Skeleton height={20} />
          </div>
          <div>
            <div className="pb-24">
              <Skeleton height={32} count={2} />
            </div>
            <Skeleton height={24} count={2} />
          </div>
          <div></div>
        </div>
      </div>
      <div className="h-[20rem] md:h-[52.8rem]">
        <Skeleton height={'100%'} />
      </div>
    </div>
  );
};

export default QuoteSkeleton;
