import BreadcrumbItemSeparator from '@components/views/Breadcrumbs/BreadcrumbItemSeparator';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface BreadcrumbsSkeletonItemProps {
  isLastChild?: boolean;
}

const BreadcrumbsSkeletonItem = ({
  isLastChild,
}: BreadcrumbsSkeletonItemProps) => {
  return (
    <div className="flex items-center">
      <Skeleton height={16} width={'10rem'} />
      {!isLastChild && <BreadcrumbItemSeparator />}
    </div>
  );
};

export default BreadcrumbsSkeletonItem;
