import CtaSkeleton from '@components/views/Skeletons/CtaSkeleton';
import FooterSkeleton from '@components/views/Skeletons/FooterSkeleton';
import GridSkeleton from '@components/views/Skeletons/GridSkeleton';
import HeroSkeleton from '@components/views/Skeletons/HeroSkeleton';
import NavSkeleton from '@components/views/Skeletons/NavSkeleton';
import PictureAndDescriptionsSkeleton from '@components/views/Skeletons/PictureAndDescriptionsSkeleton';
import QuoteSkeleton from '@components/views/Skeletons/QuoteSkeleton';
import SidebarSkeleton from '@components/views/Skeletons/SidebarSkeleton';
import ThreeTilesSkeleton from '@components/views/Skeletons/ThreeTilesSkeleton';
import Container from '../Container';

export interface PageSkeletonProps {
  isSidebarActive?: boolean;
}

const PageSkeleton = ({ isSidebarActive }: PageSkeletonProps) => {
  return (
    <div>
      <Container>
        <NavSkeleton />
        <div className="flex flex-wrap lg:flex-nowrap gap-48 lg:gap-64">
          {isSidebarActive && (
            <div className="flex-grow order-2 lg:-order-1 min-w-[23.6rem] lg:pt-20">
              <SidebarSkeleton />
            </div>
          )}
          <div className="grid gap-64 sm:gap-96 md:gap-134 flex-grow-[2]">
            <HeroSkeleton />
            <ThreeTilesSkeleton />
            <PictureAndDescriptionsSkeleton />
            <PictureAndDescriptionsSkeleton reverseSection />
            <QuoteSkeleton />
            <CtaSkeleton />
            <GridSkeleton />
          </div>
        </div>
      </Container>
      <FooterSkeleton />
    </div>
  );
};

export default PageSkeleton;
