import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ThreeTilesSkeleton = () => {
  return (
    <div>
      <div className="md:w-2/5 pb-32 sm:pb-48 md:pb-64">
        <Skeleton height={48} />
      </div>
      <div className="grid md:grid-cols-3 gap-32">
        <div>
          <Skeleton height={256} />
          <div className="pt-20 pb-16">
            <Skeleton height={24} />
          </div>
          <Skeleton height={16} />
          <Skeleton height={16} />
          <Skeleton height={16} />{' '}
        </div>
        <div>
          <Skeleton height={256} />
          <div className="pt-20 pb-16">
            <Skeleton height={24} />
          </div>
          <Skeleton height={16} />
          <Skeleton height={16} />
          <Skeleton height={16} />{' '}
        </div>
        <div>
          <Skeleton height={256} />
          <div className="pt-20 pb-16">
            <Skeleton height={24} />
          </div>
          <Skeleton height={16} />
          <Skeleton height={16} />
          <Skeleton height={16} />{' '}
        </div>
      </div>
    </div>
  );
};

export default ThreeTilesSkeleton;
