import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import GridSkeletonElement from './GridSkeletonElement';

const GridSkeleton = () => {
  return (
    <div className="grid grid-cols-1 gap-48 md:gap-64">
      <div className="md:w-1/2">
        <div className="pb-16 md:w-2/3">
          <Skeleton height={40} />
        </div>
        <Skeleton height={16} count={2} />
      </div>
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-32">
        <GridSkeletonElement />
        <GridSkeletonElement />
        <GridSkeletonElement />
        <GridSkeletonElement />
      </div>
    </div>
  );
};

export default GridSkeleton;
