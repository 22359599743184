import PageSkeleton, {
  PageSkeletonProps,
} from '@components/containers/PageSkeleton';

interface IPageFallbackLoader {
  skeletonProps?: PageSkeletonProps;
}

const PageFallbackLoader = (props: IPageFallbackLoader) => {
  return (
    <div>
      <PageSkeleton {...props.skeletonProps} />
    </div>
  );
};

export default PageFallbackLoader;
