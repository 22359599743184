import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SidebarSkeleton = () => {
  return (
    <div className="lg:sticky lg:top-[10%] lg:left-0">
      <Skeleton height={14} />
      <div className="mb-24 pt-44 pb-16 border-b-[.1rem] border-neutral-1">
        <Skeleton height={14} />
      </div>
      <div className="pb-24">
        <Skeleton height={24} />
      </div>
      <Skeleton height={48} count={5} />
    </div>
  );
};

export default SidebarSkeleton;
