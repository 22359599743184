import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CtaSkeleton = () => {
  return (
    <div className="grid md:grid-cols-2 gap-48 md:gap-96">
      <div className="h-[25rem] md:h-[46.2rem]">
        <Skeleton height={'100%'} />
      </div>
      <div className="flex flex-col justify-center">
        <div>
          <Skeleton height={40} count={2} />
        </div>
        <div className="pt-16 pb-40">
          <Skeleton height={24} />
        </div>
        <div className="flex items-center gap-32 flex-wrap">
          <Skeleton height={56} width={193} />
          <div className="flex items-center gap-16">
            <Skeleton height={24} width={166} />
            <Skeleton circle width={56} height={56} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaSkeleton;
