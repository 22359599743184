import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface FooterSkeletonTileProps {
  textLinesCount: number;
}

const FooterSkeletonTile = ({ textLinesCount }: FooterSkeletonTileProps) => {
  return (
    <div className="flex-1">
      <div className="pb-8">
        <Skeleton height={24} />
      </div>
      <Skeleton height={18} width={100} count={textLinesCount} />
    </div>
  );
};

export default FooterSkeletonTile;
